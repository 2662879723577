import React, { useState, useRef, useEffect, Fragment } from 'react'
import { useSpring, animated, useTrail } from 'react-spring'
import { useStaticQuery, graphql } from 'gatsby'
import { ArrowProps } from 'react-multi-carousel'
import Img from 'gatsby-image'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import ArrowLeft from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'
import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'

import ComoAtualizarSuaSenha from '../../assets/data/atualizacao-de-senha.json'

import { TextCarousel, HowToRequestSection } from './style'
import usePageQuery from './pageQuery'

type ComoAtualizarSuaSenhaProps= {
  question: string;
  title: string;
  description?: string;
  image: string;
}

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const CarouselAtualizarSenha = () => {
  const data = usePageQuery()
  const scroll = useScroll(300)
  const [ imageIndex, setImageIndex ] = useState(0)
  const ComoAtualizarSuaSenhaJSON = ComoAtualizarSuaSenha

  const imageNames = [
   'EsqueciMinhaSenha',
   'AutenticacaoCVV',
   'RevisarFoto',
   'AutenticacaoSMS',
   'SenhaForte',
   'NovaSenhaGerada',
  ]

  const images = useStaticQuery(graphql`
    query {
      EsqueciMinhaSenha: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-01-esqueci-minha-senha/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      AutenticacaoCVV: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-02-autenticacao-cvv/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      RevisarFoto: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-03-revisar-foto/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      AutenticacaoSMS: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-04-autenticao-sms/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      SenhaForte: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-05-senha-forte/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      NovaSenhaGerada: imageSharp(fluid: {originalName: { regex: "/atualizar-sua-senha-step-06-nova-senha-gerada/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  function changeImage (nextSlide: number) {
    setImageIndex(nextSlide)
  }

  // Refs
  const carouselRef = useRef<HTMLElement>(null)

  // Animations
  const [ animatedCarouselImage, setAnimatedCarouselImage ] = useSpring(() => (translateFrom))
  const [ animatedCarousel, setAnimatedCarousel ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(carouselRef.current, -0.3)) {
      setAnimatedCarouselImage((translateTo))
    }
    if (isVisible(carouselRef.current, -0.3)) {
      setAnimatedCarousel((fadeTo))
    }
  }, [ scroll ])

 return (
   <HowToRequestSection ref={carouselRef} className='bg-orange--extra py-xl-5' id='esqueci-a-senha'>
     <div className='container py-5'>
       <div className='row align-items-center'>
         <div className='col-12 col-md-6 col-xl-5'>
           <DefaultCarousel
             sm={{ items: 1 }}
             md={{ items: 1 }}
             lg={{ items: 1 }}
             xl={{ items: 1 }}
             beforeChange={(nextSlide: number) => changeImage(nextSlide)}
           >
             { ComoAtualizarSuaSenhaJSON.map((item: ComoAtualizarSuaSenhaProps) => (
               <Fragment key={item.title}>
                 <TextCarousel>
                   <animated.h2
                     style={animatedCarousel[0]}
                     className='fs-20 lh-25 fs-md-24 lh-md-30 fs-xl-24 lh-xl-30 pb-2 pb-md-3 text-white'
                   >{item.question}
                   </animated.h2>
                   <animated.p
                     style={animatedCarousel[0]}
                     className='fs-20 lh-25 fs-lg-32 fs-lg-40 lh-lg-50 text-white fw-600 mb-3'
                   >{item.title}
                   </animated.p>
                   <animated.p
                     style={animatedCarousel[0]}
                     className='fs-18 lh-22 fs-xl-24 lh-xl-30 text-white'
                   >{item.description}
                   </animated.p>
                 </TextCarousel>
                 <animated.div
                   style={animatedCarouselImage}
                   className='col-12 col-md-6 col-lg-5 offset-lg-1 d-md-none pt-4 pb-3 py-md-0'
                 >
                   <Img fluid={data[item.image].fluid} alt={item.title} />
                 </animated.div>
               </Fragment>
                ),
              )}
           </DefaultCarousel>
         </div>
         <animated.div style={animatedCarouselImage} className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2 d-none d-md-block'>
           <Img fluid={images[imageNames[imageIndex]].fluid} alt='Telas de atualização de senha' />
         </animated.div>
       </div>
     </div>
   </HowToRequestSection>
 )
}

export default CarouselAtualizarSenha
